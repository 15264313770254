import {
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  createMuiTheme,
  MuiThemeProvider,
  TextField,
  Tooltip,
  Typography,
  Card as CardMaterial,
  CardMedia,
  Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AddComment,
  AddPhotoAlternate,
  Delete,
  Launch,
  NoteAdd,
  RateReview,
  Visibility,
  Create,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getArtists } from "../../../../api/artist";
import {
  deleteArtwork,
  getArtworkById,
  getArtworks,
  postArtwork,
  updateArtwork,
} from "../../../../api/artwork";
import { getArtworkTypes } from "../../../../api/artworkType";
import { getCountries } from "../../../../api/country";
import { postLog } from "../../../../api/log";
import { getSuppliers } from "../../../../api/supplier";
import { LOGS } from "../../../../utils/constants";
import { checkIsEmpty, findSimilarArtworks } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ArtworkTableDialog from "../../../components/dialogs/ArtworkTableDialog";
import CommentDialog from "../../../components/dialogs/CommentDialog";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import FilePickerDialog from "../../../components/dialogs/FilePickerDialog";
import ImageCarouselDialog from "../../../components/dialogs/ImageCarouselDialog";
import ImagePickerDialog from "../../../components/dialogs/ImagePickerDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import SupplierTableDialog from "../../../components/dialogs/SupplierTableDialog";
import ItemCountBadge from "../../../components/ItemCountBadge";
import Table, {
  buttonsStyle,
  substringFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const useStyles = makeStyles({
  preview: {
    width: 50,
    height: 50,
    objectFit: "cover",
    cursor: "zoom-in",
  },
});

function formatDate(date) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = new Date(date).toLocaleString("en-GB", options);
  return formattedDate;
}

export function getEmptyArtwork() {
  return {
    title: "",
    artworkType: "",
    artist: null,
    size: null,
    technique: null,
    year: null,
    imageURL: null,
    signature: null,
    uniqueness: null,
    catalogued: null,
    certified: null,
    color: null,
    provenance: null,
    subject: null,
    artworkSuppliers: [],
  };
}

export default function EditArtworksPage() {
  const [artwork, setArtwork] = useState(getEmptyArtwork());

  const [artworks, setArtworks] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [artists, setArtists] = useState([]);
  const [artworkTypes, setArtworkTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [countries, setCountries] = useState([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSupplierTableDialog, setOpenSupplierTableDialog] = useState(false);
  const [openSupplierDateDialog, setOpenSupplierDateDialog] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const [selectedArtworks, setSelectedArtworks] = useState([]);
  const [openArtworkTableDialog, setOpenArtworkTableDialog] = useState(false);

  const [selectedArtworkSupplier, setSelectedArtworkSupplier] = useState(null);

  const [carouselImages, setCarouselImages] = useState([]);
  const [openCarouselDialog, setOpenCarouselDialog] = useState(false);

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [openFilePickerDialog, setOpenFilePickerDialog] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const [refresh, setRefresh] = useState(null);

  const artworkId = useParams().id;
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const loggedUser = useSelector(
    (store) => store.authentication?.user,
    shallowEqual
  );

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getArtworks()
      .then((res) => {
        if (res.status === 200) {
          setArtworks(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artworks.",
        });
        handleBack();
      });
    getArtists()
      .then((res) => {
        if (res.status === 200) {
          setArtists(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artists.",
        });
        handleBack();
      });
    getArtworkTypes()
      .then((res) => {
        if (res.status === 200) {
          setArtworkTypes(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artwork types",
        });
        handleBack();
      });
    getSuppliers()
      .then((res) => {
        if (res.status === 200) {
          setSuppliers(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get suppliers.",
        });
        handleBack();
      });
    getCountries()
      .then((res) => {
        if (res.status === 200) {
          setCountries(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get countries.",
        });
        handleBack();
      });
    if (!artworkId) {
      disableLoadingData();
      return;
    }
    getArtworkById(artworkId)
      .then((res) => {
        if (res.status === 200) {
          setArtwork({ ...artwork, ...res.data });
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artwork.",
        });
        handleBack();
      });
  }, [artworkId, disableLoadingData, history]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  const handleBack = () => {
    const prevTablePage = location?.state?.page;

    history.push(`/artworks${prevTablePage ? "?page=" + prevTablePage : ""}`);
  };

  function handleSaveArtwork() {
    if (checkIsEmpty(artwork.title) || checkIsEmpty(artwork.artworkType)) {
      alertError({ error: "Title and type are required" });
      return;
    }
    const similarArtworks = findSimilarArtworks({
      artworks: artworks,
      artwork: artwork,
    });
    if (similarArtworks?.length > 0) {
      setSelectedArtworks(similarArtworks);
      setOpenArtworkTableDialog(true);
    } else saveArtwork();
  }

  async function saveArtwork() {
    if (!artworkId) {
      postArtwork(artwork, selectedImage)
        .then((res) => {
          if (res.status === 201) {
            postLog(
              loggedUser,
              LOGS.FIELDS.ARTWORKS,
              `${LOGS.ACTIONS.CREATE}-${artwork?.title}`
            );
            alertSuccess({
              title: "Saved!",
              customMessage: "Artwork successfully created.",
            });
            handleBack();
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save artwork.",
          });
        });
    } else {
      updateArtwork(artworkId, artwork, selectedImage)
        .then((res) => {
          if (res.status === 200) {
            postLog(
              loggedUser,
              LOGS.FIELDS.ARTWORKS,
              `${LOGS.ACTIONS.UPDATE}-${artwork?.title}`
            );
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            handleBack();
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setArtwork({ ...artwork, [element]: event.target.value });
  };

  function getArtworkSupplierData(artworkSuppliers) {
    let data = [];
    // console.log("ARTWORK SUPPLIERS", artworkSuppliers);
    for (let i = 0; i < artworkSuppliers?.length; ++i) {
      const elem = {};
      const artworkSupplier = artworkSuppliers[i];
      // console.log(artworkSupplier);

      elem.fullName = suppliers?.find(
        (x) => x._id === artworkSupplier?.supplier
      )?.fullName;
      elem.comments = artworkSupplier?.comments;
      elem.id = artworkSupplier?.supplier;
      elem.date = artworkSupplier?.date
        ? formatDate(artworkSupplier?.date)
        : "N/A";
      elem.multimedia = artworkSupplier?.supplier;

      data = data.concat(elem);
    }
    // console.log("UPDATED DATA", data);
    return data;
  }

  function customDateFormatter(cell, row) {
    return (
      <>
        <Tooltip title={"Date"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setSelectedSupplier(row.id);
              setDate(cell);
              setOpenSupplierDateDialog(true);
            }}
          >
            <span>{cell}</span>
            <Create style={{ fontSize: "small" }} />
          </Button>
        </Tooltip>
      </>
    );
  }

  const artworkSuppliersColumns = [
    {
      dataField: "multimedia",
      text: "Multimedia",
      sort: true,
      formatter: multiMediaFormatter,
    },
    { dataField: "fullName", text: "Full Name", sort: true },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => customDateFormatter(cell, row),
    },
    {
      dataField: "comments",
      text: "Comments",
      sort: true,
      formatter: substringFormatter,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  function multiMediaFormatter(cell) {
    const elem = artwork.artworkSuppliers?.find((x) => x.supplier === cell);

    // console.log("ELEMENT: ", elem);

    const firstMedia = elem?.imagesURLs?.[0] || elem?.images?.[0];

    const isVideo = (url) => {
      return url
        ? typeof url === "object"
          ? url?.name?.endsWith(".mp4") ||
            url?.name?.endsWith(".webm") ||
            url?.name?.endsWith(".ogg")
          : url?.endsWith(".mp4") ||
            url?.endsWith(".webm") ||
            url?.endsWith(".ogg")
        : false;
    };
    const getMediaSrc = (media) => {
      return typeof media === "string"
        ? SERVER_URL + "/" + media
        : URL.createObjectURL(media);
    };

    return (
      <>
        <Tooltip title={"Media"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setCarouselImages(
                (elem?.imagesURLs || []).concat(elem?.images || [])
              );
              setOpenCarouselDialog(true);
            }}
          >
            {firstMedia ? (
              isVideo(firstMedia) ? (
                <video
                  className={classes.preview}
                  src={getMediaSrc(firstMedia)}
                />
              ) : (
                <img
                  className={classes.preview}
                  src={getMediaSrc(firstMedia)}
                  alt="preview"
                />
              )
            ) : (
              <AddPhotoAlternate />
            )}
            <ItemCountBadge
              howMany={
                (elem?.imagesURLs?.length || 0) + (elem?.images?.length || 0)
              }
            />
          </Button>
        </Tooltip>
      </>
    );
  }

  function buttonFormatter(cell) {
    const elem = artwork.artworkSuppliers?.find((x) => x.supplier === cell);

    return (
      <>
        <Tooltip title={!elem?.comments ? "Add comments" : "Edit comments"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpenCommentDialog(true);
              setSelectedArtworkSupplier(elem);
            }}
          >
            {!elem?.comments ? <AddComment /> : <RateReview />}
            {elem?.comments && <ItemCountBadge howMany={1} />}
          </Button>
        </Tooltip>
        <Tooltip
          title={
            !elem?.imagesURLs?.length > 0 && !elem?.images?.length > 0
              ? "Add Images"
              : "Edit Images"
          }
        >
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpenImagePickerDialog(true);
              setSelectedArtworkSupplier(elem);
            }}
          >
            <AddPhotoAlternate />
            {(elem?.imagesURLs?.length > 0 || elem?.images?.length > 0) && (
              <ItemCountBadge
                howMany={
                  (elem?.imagesURLs?.length || 0) + (elem?.images?.length || 0)
                }
              />
            )}
          </Button>
        </Tooltip>
        <Tooltip
          title={
            !elem?.filesURLs?.length > 0 && !elem?.files?.length > 0
              ? "Add documentation"
              : "Edit documentation"
          }
        >
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpenFilePickerDialog(true);
              setSelectedArtworkSupplier(elem);
            }}
          >
            <NoteAdd />
            {(elem?.filesURLs?.length > 0 || elem?.files?.length > 0) && (
              <ItemCountBadge
                howMany={
                  (elem?.filesURLs?.length || 0) + (elem?.files?.length || 0)
                }
              />
            )}
          </Button>
        </Tooltip>
        <Tooltip title={"See supplier"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              history.push("/suppliers/" + elem?.supplier + "/edit");
            }}
          >
            <Launch />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              let artworkSuppliers = [...artwork.artworkSuppliers];
              artworkSuppliers = artworkSuppliers.filter(
                (x, index) => x.supplier !== cell
              );
              setArtwork({
                ...artwork,
                artworkSuppliers: artworkSuppliers,
              });
            }}
          >
            <Delete />
          </Button>
        </Tooltip>
      </>
    );
  }

  const colorOptions = [
    { label: "Red", value: "red", colorCode: "#FF0000" },
    { label: "Green", value: "green", colorCode: "#00FF00" },
    { label: "Yellow", value: "yellow", colorCode: "#FFFF00" },
  ];

  const uniquenessOptions = [
    { label: "Original", value: "Original" },
    { label: "Edition", value: "Edition" },
  ];

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit artwork">
            <CardHeaderToolbar>
              <Button
                onClick={() => handleSaveArtwork()}
                variant="outlined"
                color="primary"
              >
                Save artwork
              </Button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <CardMaterial>
                  <CardMedia
                    component="img"
                    alt="Artwork"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "auto",
                      maxHeight: "600px",
                    }}
                    image={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : `${SERVER_URL}/${artwork.imageURL}`
                    }
                  />
                </CardMaterial>
                <label htmlFor={"upload-media"} className="ml-0 my-4">
                  <input
                    style={{ display: "none" }}
                    id={"upload-media"}
                    name={"upload-media"}
                    type="file"
                    accept={"image/*"}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                    }}
                  />
                  <Button
                    style={{ marginRight: "15px" }}
                    color="secondary"
                    component="span"
                    variant="outlined"
                  >
                    {selectedImage ||
                    (artwork.imageURL && artwork.imageURL !== "")
                      ? "Change media"
                      : "Upload media"}
                  </Button>
                </label>
                {(selectedImage ||
                  (artwork.imageURL && artwork.imageURL !== "")) && (
                  <>
                    <Tooltip title={"Preview"}>
                      <Button
                        size="small"
                        onClick={() => setOpenPreviewDialog(true)}
                        style={{
                          ...buttonsStyle,
                          marginRight: "15px",
                        }}
                      >
                        <Visibility />
                      </Button>
                    </Tooltip>
                    <PreviewDialog
                      title={"Preview"}
                      open={openPreviewDialog}
                      setOpen={setOpenPreviewDialog}
                      file={selectedImage}
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : `${SERVER_URL}/${artwork.imageURL}`
                      }
                    />
                    <Tooltip title={"Delete"}>
                      <Button
                        size="small"
                        onClick={() => {
                          setSelectedImage(null);
                          setArtwork({
                            ...artwork,
                            imageURL: null,
                          });
                        }}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
              <div className="col-md-4">
                <Autocomplete
                  id="autocomplete-color"
                  disablePortal
                  options={colorOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    colorOptions.find((x) => x.value === artwork.color) || ""
                  }
                  onChange={(event, selected) => {
                    setArtwork({
                      ...artwork,
                      color: selected ? selected.value : null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Color"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <Box
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: "50%",
                              backgroundColor: params.inputProps.value
                                ? colorOptions.find(
                                    (x) => x.label === params.inputProps.value
                                  )?.colorCode
                                : "transparent",
                              marginRight: 8,
                            }}
                          />
                        ),
                      }}
                    />
                  )}
                  renderOption={(option) => (
                    <Box display="flex" alignItems="center">
                      <Box
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          backgroundColor: option.colorCode,
                          marginRight: 8,
                        }}
                      />
                      {option.label}
                    </Box>
                  )}
                />
                <Autocomplete
                  id="autocomplete-artist"
                  disablePortal
                  options={artists}
                  getOptionLabel={(option) => option.fullName}
                  value={artists?.find((x) => x._id === artwork?.artist) || ""}
                  onChange={(event, selected) => {
                    setArtwork({
                      ...artwork,
                      artist: selected ? selected._id : null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Artist"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                <TextField
                  id={`fullName`}
                  label="Title"
                  value={artwork.title}
                  onChange={handleChange("title")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <Autocomplete
                  id="autocomplete-uniqueness"
                  disablePortal
                  options={uniquenessOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    uniquenessOptions.find(
                      (x) => x.value === artwork.uniqueness
                    ) || ""
                  }
                  onChange={(event, selected) => {
                    setArtwork({
                      ...artwork,
                      uniqueness: selected ? selected.value : null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Uniqueness"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="autocomplete-artworkType"
                  disablePortal
                  disableClearable
                  options={artworkTypes}
                  getOptionLabel={(option) => option.title}
                  value={
                    artworkTypes?.find((x) => x._id === artwork?.artworkType) ||
                    ""
                  }
                  onChange={(event, selected) => {
                    setArtwork({
                      ...artwork,
                      artworkType: selected ? selected._id : null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Artwork Type"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  )}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  id={`size`}
                  label="Size"
                  value={artwork.size}
                  onChange={handleChange("size")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id={`technique`}
                  label="Technique"
                  value={artwork.technique}
                  onChange={handleChange("technique")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id={`year`}
                  label="Year"
                  value={artwork.year}
                  onChange={handleChange("year")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id={`signature`}
                  label="Signature"
                  value={artwork.signature}
                  onChange={handleChange("signature")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
                {/* TODO[] ADD SUBJECT AS A NEW ENTITY, IT HAS TO BE AN AUTOCOMPLETE */}
                <TextField
                  id={`subject`}
                  label="Subject"
                  value={artwork.subject}
                  onChange={handleChange("subject")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id={`catalogued`}
                  label="Catalogued"
                  value={artwork.catalogued}
                  onChange={handleChange("catalogued")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id={`certified`}
                  label="Certified"
                  value={artwork.certified}
                  onChange={handleChange("certified")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
            <br />
            <br />
            <TextField
              id="provenance"
              label="Notas"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={artwork.provenance}
              onChange={handleChange("provenance")}
            />
          </CardBody>
          <CardHeader title="Suppliers" sticky={false}>
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setOpenSupplierTableDialog(true);
                }}
              >
                Add new
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            {artwork?.artworkSuppliers?.length > 0 && !refresh && (
              <Table
                data={getArtworkSupplierData(artwork?.artworkSuppliers)}
                columns={artworkSuppliersColumns}
              />
            )}
          </CardBody>
        </Card>
        <Button
          onClick={handleBack}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => handleSaveArtwork()}
          variant="outlined"
          color="primary"
          style={artworkId && { marginRight: "20px" }}
        >
          Save artwork
        </Button>
        {artworkId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                className="py-2"
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete artwork
              </Button>
            </MuiThemeProvider>
            <ConfirmDialog
              title={"Are you sure you want to delete this artwork?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteArtwork(artworkId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      postLog(
                        loggedUser,
                        LOGS.FIELDS.ARTWORKS,
                        `${LOGS.ACTIONS.DELETE}-${artwork?.title}`
                      );
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Artwork deleted successfully.",
                      });
                      handleBack();
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete artwork.",
                    });
                  });
              }}
            />
          </>
        )}
        <SupplierTableDialog
          title="Suppliers"
          open={openSupplierTableDialog}
          setOpen={setOpenSupplierTableDialog}
          data={suppliers.filter(
            (x) => !artwork?.artworkSuppliers?.some((y) => y.supplier === x._id)
          )}
          onSelectRow={(supplierId) => {
            console.log("SUPPLIER ID", supplierId);
            let artworkSuppliers = artwork.artworkSuppliers;
            artworkSuppliers.push({ supplier: supplierId });

            console.log("ARTWORK SUPPLIERS: ", artworkSuppliers);

            setOpenSupplierDateDialog(true);
            setSelectedSupplier(supplierId);
            setArtwork({ ...artwork, artworkSuppliers: artworkSuppliers });
          }}
        />

        <Modal
          open={openSupplierDateDialog}
          onClose={() => setOpenSupplierDateDialog(false)}
          aria-labelledby="supplier-date-dialog"
          aria-describedby="Date dialog for a supplier"
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width={400}
            bgcolor="background.paper"
            border="2px solid #000"
            boxShadow={24}
            p={4}
          >
            <Typography id="supplier-date-dialog" variant="h6">
              Supplier Date
            </Typography>
            <TextField
              id="date"
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary"
              onClick={() => {
                const updatedArtworkSuppliers = [...artwork.artworkSuppliers];

                const index = updatedArtworkSuppliers.findIndex(
                  (x) => x.supplier === selectedSupplier
                );

                if (index !== -1) {
                  updatedArtworkSuppliers[index] = {
                    ...updatedArtworkSuppliers[index],
                    date: date,
                  };

                  setArtwork({
                    ...artwork,
                    artworkSuppliers: updatedArtworkSuppliers,
                  });
                }

                setOpenSupplierDateDialog(false);
              }}
            >
              Save
            </Button>
          </Box>
        </Modal>
        <CommentDialog
          title={"Additional Comments"}
          open={openCommentDialog}
          setOpen={setOpenCommentDialog}
          data={selectedArtworkSupplier?.comments}
          onSave={(comments) => {
            let artworkSuppliers = [...artwork.artworkSuppliers];

            let index = artworkSuppliers.findIndex(
              (x) => x.supplier === selectedArtworkSupplier?.supplier
            );
            artworkSuppliers[index] = {
              ...artworkSuppliers[index],
              comments: comments,
            };

            setArtwork({
              ...artwork,
              artworkSuppliers: artworkSuppliers,
            });

            setRefresh(true);
          }}
        />
        <ImagePickerDialog
          title={"Images"}
          open={openImagePickerDialog}
          setOpen={setOpenImagePickerDialog}
          data={[
            ...(selectedArtworkSupplier?.imagesURLs ?? []),
            ...(selectedArtworkSupplier?.images ?? []),
          ]}
          onSave={(images) => {
            let artworkSuppliers = [...artwork.artworkSuppliers];

            let index = artworkSuppliers.findIndex(
              (x) => x.supplier === selectedArtworkSupplier?.supplier
            );
            artworkSuppliers[index] = {
              ...artworkSuppliers[index],
              images: images?.filter((x) => x.name),
              imagesURLs: images?.filter((x) => !x.name),
            };

            setArtwork({
              ...artwork,
              artworkSuppliers: artworkSuppliers,
            });

            setRefresh(true);
          }}
        />
        <FilePickerDialog
          title={"Archivos"}
          open={openFilePickerDialog}
          setOpen={setOpenFilePickerDialog}
          data={[
            ...(selectedArtworkSupplier?.filesURLs ?? []),
            ...(selectedArtworkSupplier?.files ?? []),
          ]}
          onSave={(files) => {
            let artworkSuppliers = [...artwork.artworkSuppliers];

            let index = artworkSuppliers.findIndex(
              (x) => x.supplier === selectedArtworkSupplier?.supplier
            );
            artworkSuppliers[index] = {
              ...artworkSuppliers[index],
              files: files?.filter((x) => x.name),
              filesURLs: files?.filter((x) => !x.name),
            };

            setArtwork({
              ...artwork,
              artworkSuppliers: artworkSuppliers,
            });

            setRefresh(true);
          }}
        />
        <ImageCarouselDialog
          open={openCarouselDialog}
          setOpen={setOpenCarouselDialog}
          images={carouselImages}
        />
        <ArtworkTableDialog
          title={"Similar Artworks: " + artwork?.title}
          subtitle={
            <div className="bg-danger text-white p-3 mb-4">
              There are some artworks that have similar features! Check them
              before saving.
            </div>
          }
          open={openArtworkTableDialog}
          setOpen={setOpenArtworkTableDialog}
          data={selectedArtworks}
          allowAdd={false}
          onSave={() => saveArtwork()}
        />
      </>
    );
}
