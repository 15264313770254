import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { postLog } from "../../../../api/log";
import { changeStatusUser, deleteUser, getUsers } from "../../../../api/user";
import { LOGS, ROLES } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	getCurrentTablePage,
	roleFormatter,
} from "../../../components/tables/table";

function getData(admins) {
	let data = [];
	for (let i = 0; i < admins.length; ++i) {
		if ([ROLES.ADMIN, ROLES.GESTOR].includes(admins[i].role)) {
			const elem = {};

			elem.name = admins[i].fullName;
			elem.email = admins[i].email;
			elem.createdAt = admins[i].createdAt;
			elem.role = admins[i].role;
			elem.id = admins[i]._id;
			elem.active = admins[i].active;

			data = data.concat(elem);
		}
	}
	return data;
}

export default function AdminsPage() {
	const [data, setData] = useState([]);
	const [selectedAdmin, setSelectedAdmin] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);

	const location = useLocation();

	const history = useHistory();
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push({
								pathname: "/admins/" + cell + "/edit",
								state: {
									page: getCurrentTablePage(location),
								},
							});
						}}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{loggedUser?.role === ROLES.ADMIN && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedAdmin(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						{loggedUser?._id !== cell && (
							<Tooltip title="Delete">
								<Button
									style={buttonsStyle}
									size="small"
									onClick={() => {
										setSelectedAdmin(cell);
										setOpenConfirmDialog(2);
									}}
								>
									<DeleteIcon />
								</Button>
							</Tooltip>
						)}
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: "name", text: "Name", sort: true },
		{ dataField: "email", text: "Email", sort: true },
		{
			dataField: "role",
			text: "Role",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: roleFormatter,
		},
		{
			dataField: "createdAt",
			text: "Created At",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get admins.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Administrators list">
					<CardHeaderToolbar>
						{loggedUser?.role === ROLES.ADMIN && (
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									history.push({
										pathname: "/admins/edit",
										state: {
											page: getCurrentTablePage(location),
										},
									});
								}}
							>
								Add new
							</button>
						)}
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={getData(data)} columns={columns} />

					<ConfirmDialog
						title={`¿Are you sure you want to ${
							selectedAdmin?.active ? "disable" : "enable"
						} this user?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusUser(
								selectedAdmin._id,
								!selectedAdmin?.active
							)
								.then((res) => {
									if (res.status === 200) {
										postLog(
											loggedUser,
											LOGS.FIELDS.ADMINS,
											`${
												selectedAdmin?.active
													? LOGS.ACTIONS.DISABLE
													: LOGS.ACTIONS.ENABLE
											}-${selectedAdmin?.fullName}`
										);
										alertSuccess({
											title: `${
												selectedAdmin?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Admin ${
												selectedAdmin?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedAdmin?.active
												? "disable"
												: "enable"
										} user.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to delete this user?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(selectedAdmin?._id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										postLog(
											loggedUser,
											LOGS.FIELDS.ADMINS,
											`${LOGS.ACTIONS.DELETE}-${selectedAdmin?.fullName}`
										);
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"User deleted successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not delete user.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
