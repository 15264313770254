import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { buttonsStyle } from "../tables/table";
import ImageCarouselDialog from "./ImageCarouselDialog";

const ImagePickerDialog = (props) => {
  const { title, open, setOpen, data, onSave, editable = true } = props;

  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(data || []);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  useEffect(() => {
    if (open) setSelectedImages(data || []);
  }, [open]);

  const handleDeleteImage = (index) => {
    let newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="table-dialog"
      >
        <DialogTitle id="table-dialog">{title}</DialogTitle>
        <DialogContent>
          {selectedImages?.map((_, index) => {
            return (
              <>
                {editable && (
                  <label htmlFor={"upload-image" + index} key={index}>
                    <input
                      style={{ display: "none" }}
                      id={"upload-image" + index}
                      name={"upload-image" + index}
                      type="file"
                      accept={"image/*,video/*"}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setSelectedImages((prevSelectedImages) => {
                          const newSelectedImages = [...prevSelectedImages];
                          newSelectedImages[index] = file;
                          return newSelectedImages;
                        });
                      }}
                      key={index}
                    />
                    <Button
                      style={{ marginRight: "15px" }}
                      color="secondary"
                      component="span"
                      variant="outlined"
                    >
                      {selectedImages && selectedImages[index]
                        ? "Change image " + (index + 1)
                        : "Upload image " + (index + 1)}
                    </Button>
                  </label>
                )}
                {selectedImages && selectedImages[index] && (
                  <>
                    <Tooltip title={"Preview image " + (index + 1)}>
                      <Button
                        size="small"
                        onClick={() => {
                          setOpenPreviewDialog(true);

                          const firstImage = selectedImages[index];
                          const remainingImages = selectedImages.filter(
                            (_, i) => i !== index
                          );

                          setCarouselImages([firstImage, ...remainingImages]);
                        }}
                        style={buttonsStyle}
                      >
                        <Visibility />
                      </Button>
                    </Tooltip>
                    {editable && (
                      <Tooltip title="Delete">
                        <Button
                          size="small"
                          style={buttonsStyle}
                          onClick={() => handleDeleteImage(index)}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    )}
                    <span className="ml-3">
                      {selectedImages &&
                        selectedImages[index] &&
                        (selectedImages[index]?.name ||
                          selectedImages[index]?.split(/-(.*)/s)[1] ||
                          "")}
                    </span>
                    <br />
                  </>
                )}
              </>
            );
          })}
          {selectedImages?.length > 0 && (
            <>
              <br />
              <br />
            </>
          )}
          {editable ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedImages([...selectedImages, null]);
              }}
            >
              Add image
            </Button>
          ) : (
            !selectedImages?.length > 0 && <div>No images available</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          {editable && (
            <Button
              className="ml-3"
              onClick={() => {
                onSave(selectedImages);
                setOpen(false);
                setSelectedImages([]);
              }}
              variant="outlined"
              color="primary"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ImageCarouselDialog
        open={openPreviewDialog}
        setOpen={setOpenPreviewDialog}
        images={carouselImages}
      />
    </>
  );
};
export default ImagePickerDialog;
