import {
  Button,
  createMuiTheme,
  Tooltip,
  Card as CardMaterial,
  CardMedia,
} from "@material-ui/core";
import {
  InsertDriveFile,
  Launch,
  PhotoLibrary,
  RateReview,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getArtists } from "../../../../api/artist";
import { getArtworkById } from "../../../../api/artwork";
import { getArtworkTypes } from "../../../../api/artworkType";
import { getCountries } from "../../../../api/country";
import { getSuppliers } from "../../../../api/supplier";
import { alertError } from "../../../../utils/logger";
import CommentDialog from "../../../components/dialogs/CommentDialog";
import FilePickerDialog from "../../../components/dialogs/FilePickerDialog";
import ImageCarouselDialog from "../../../components/dialogs/ImageCarouselDialog";
import ImagePickerDialog from "../../../components/dialogs/ImagePickerDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import ItemCountBadge from "../../../components/ItemCountBadge";
import Table, {
  buttonsStyle,
  substringFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

export function getEmptyArtwork() {
  return {
    title: "",
    artworkType: "",
    artist: null,
    size: null,
    technique: null,
    year: null,
    imageURL: null,
    conservationStatus: null,
    signature: null,
    subject: null,
    originCountry: null,
    currentLocation: null,
    provenance: null,
    artworkSuppliers: [],
  };
}

export default function ViewArtworksPage() {
  const [artwork, setArtwork] = useState(getEmptyArtwork());

  const [artists, setArtists] = useState([]);
  const [artworkTypes, setArtworkTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [countries, setCountries] = useState([]);

  const [selectedArtworkSupplier, setSelectedArtworkSupplier] = useState(null);

  const [carouselImages, setCarouselImages] = useState([]);
  const [openCarouselDialog, setOpenCarouselDialog] = useState(false);

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
  const [openFilePickerDialog, setOpenFilePickerDialog] = useState(false);

  const [refresh, setRefresh] = useState(null);

  const artworkId = useParams().id;
  const history = useHistory();
  const location = useLocation();

  const loggedUser = useSelector(
    (store) => store.authentication?.user,
    shallowEqual
  );

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getArtists()
      .then((res) => {
        if (res.status === 200) {
          setArtists(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artists.",
        });
        handleBack();
      });
    getArtworkTypes()
      .then((res) => {
        if (res.status === 200) {
          setArtworkTypes(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artwork types",
        });
        handleBack();
      });
    getSuppliers()
      .then((res) => {
        if (res.status === 200) {
          setSuppliers(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get suppliers.",
        });
        handleBack();
      });
    getCountries()
      .then((res) => {
        if (res.status === 200) {
          setCountries(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get countries.",
        });
        handleBack();
      });
    if (!artworkId) {
      disableLoadingData();
      return;
    }
    getArtworkById(artworkId)
      .then((res) => {
        if (res.status === 200) {
          setArtwork({ ...artwork, ...res.data });
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artwork.",
        });
        handleBack();
      });
  }, [artworkId, disableLoadingData, history]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  const handleBack = () => {
    const prevTablePage = location?.state?.page;

    history.push(`/artworks${prevTablePage ? "?page=" + prevTablePage : ""}`);
  };

  const handleChange = (element) => (event) => {
    setArtwork({ ...artwork, [element]: event.target.value });
  };

  function getArtworkSupplierData(artworkSuppliers) {
    let data = [];
    for (let i = 0; i < artworkSuppliers?.length; ++i) {
      const elem = {};
      const artworkSupplier = artworkSuppliers[i];

      elem.fullName = suppliers?.find(
        (x) => x._id === artworkSupplier?.supplier
      )?.fullName;
      elem.comments = artworkSupplier?.comments;
      elem.id = artworkSupplier?.supplier;

      data = data.concat(elem);
    }
    return data;
  }

  const artworkSuppliersColumns = [
    { dataField: "id", text: "", formatter: imageFormatter },
    { dataField: "fullName", text: "Full Name", sort: true },
    {
      dataField: "comments",
      text: "Comments",
      sort: true,
      formatter: substringFormatter,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  function imageFormatter(cell) {
    const elem = artwork.artworkSuppliers?.find((x) => x.supplier === cell);

    const images = (elem.imagesURLs || [])?.concat(elem?.images || []);

    return images && images?.length > 0 ? (
      <img
        src={
          typeof images[0] === "string"
            ? SERVER_URL + "/" + images[0]
            : URL.createObjectURL(images[0])
        }
        alt="artwork"
        style={{ width: "50px", cursor: "zoom-in" }}
        onClick={() => {
          setCarouselImages(images);
          setOpenCarouselDialog(true);
        }}
      />
    ) : (
      <div />
    );
  }

  function buttonFormatter(cell) {
    const elem = artwork.artworkSuppliers?.find((x) => x.supplier === cell);

    return (
      <>
        <Tooltip title={"comments"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpenCommentDialog(true);
              setSelectedArtworkSupplier(elem);
            }}
          >
            {<RateReview />}
            {elem?.comments && <ItemCountBadge howMany={1} />}
          </Button>
        </Tooltip>
        <Tooltip title={"Images"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpenImagePickerDialog(true);
              setSelectedArtworkSupplier(elem);
            }}
          >
            <PhotoLibrary />
            {(elem?.imagesURLs?.length > 0 || elem?.images?.length > 0) && (
              <ItemCountBadge
                howMany={
                  (elem?.imagesURLs?.length || 0) + (elem?.images?.length || 0)
                }
              />
            )}
          </Button>
        </Tooltip>
        <Tooltip title={"Documentation"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setOpenFilePickerDialog(true);
              setSelectedArtworkSupplier(elem);
            }}
          >
            <InsertDriveFile />
            {(elem?.filesURLs?.length > 0 || elem?.files?.length > 0) && (
              <ItemCountBadge
                howMany={
                  (elem?.filesURLs?.length || 0) + (elem?.files?.length || 0)
                }
              />
            )}
          </Button>
        </Tooltip>
        <Tooltip title={"See supplier"}>
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              history.push("/suppliers/" + elem?.supplier + "/edit");
            }}
          >
            <Launch />
          </Button>
        </Tooltip>
      </>
    );
  }

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="View artwork"></CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <CardMaterial>
                  <CardMedia
                    component="img"
                    alt="Artwork"
                    image={`${SERVER_URL}/${artwork.imageURL}`}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "auto",
                      maxHeight: "400px",
                    }}
                  />
                </CardMaterial>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <strong>Color</strong>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: artwork?.color || "transparent",
                        borderRadius: "50%",
                      }}
                    />
                    <strong>Artist</strong>
                    <p>
                      {artists?.find((x) => x._id === artwork?.artist)
                        ?.fullName || "---"}
                    </p>

                    <strong>Title</strong>
                    <p>{artwork?.title || "---"}</p>

                    <strong>Uniqueness</strong>
                    <p>{artwork?.uniqueness || "---"}</p>

                    <strong>Artwork Type</strong>
                    <p>
                      {artworkTypes?.find((x) => x._id === artwork?.artworkType)
                        ?.title || "---"}
                    </p>

                    <strong>Signature</strong>
                    <p>{artwork?.signature || "---"}</p>
                  </div>

                  {/* Right Column for Text Fields */}
                  <div className="col-md-6">
                    <strong>Size</strong>
                    <p>{artwork?.size || "---"}</p>

                    <strong>Technique</strong>
                    <p>{artwork?.technique || "---"}</p>

                    <strong>Year</strong>
                    <p>{artwork?.year || "---"}</p>

                    <strong>Subject</strong>
                    <p>{artwork?.subject || "---"}</p>

                    <strong>Catalogued</strong>
                    <p>{artwork?.catalogued}</p>

                    <strong>Certified</strong>
                    <p>{artwork?.certified}</p>

                    <strong>Notas</strong>
                    <p>{artwork?.provenance || "---"}</p>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
          <CardHeader title="Suppliers" sticky={false}></CardHeader>
          <CardBody>
            {artwork?.artworkSuppliers?.length > 0 && !refresh && (
              <Table
                data={getArtworkSupplierData(artwork?.artworkSuppliers)}
                columns={artworkSuppliersColumns}
              />
            )}
          </CardBody>
        </Card>
        <Button onClick={handleBack} variant="outlined">
          Back
        </Button>
        <CommentDialog
          title={"Additional Comments"}
          open={openCommentDialog}
          setOpen={setOpenCommentDialog}
          data={selectedArtworkSupplier?.comments}
          editable={false}
        />
        <ImagePickerDialog
          title={"Images"}
          open={openImagePickerDialog}
          setOpen={setOpenImagePickerDialog}
          data={[
            ...(selectedArtworkSupplier?.imagesURLs ?? []),
            ...(selectedArtworkSupplier?.images ?? []),
          ]}
          editable={false}
        />
        <FilePickerDialog
          title={"Archivos"}
          open={openFilePickerDialog}
          setOpen={setOpenFilePickerDialog}
          data={[
            ...(selectedArtworkSupplier?.filesURLs ?? []),
            ...(selectedArtworkSupplier?.files ?? []),
          ]}
          editable={false}
        />
        <ImageCarouselDialog
          open={openCarouselDialog}
          setOpen={setOpenCarouselDialog}
          images={carouselImages}
        />
      </>
    );
}
