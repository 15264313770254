import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useEffect } from "react";
import Table, { buttonsStyle, dateFormatter } from "../tables/table";
import paginationFactory from "react-bootstrap-table2-paginator";

function getData(suppliers) {
  let data = [];
  for (let i = 0; i < suppliers.length; ++i) {
    const elem = {};
    const supplier = suppliers[i];

    elem.fullName = supplier?.fullName;
    elem.town = supplier?.town;
    elem.province = supplier?.province;
    elem.createdAt = supplier?.createdAt;
    elem.id = supplier?._id;

    data = data.concat(elem);
  }
  return data;
}

const SupplierTableDialog = (props) => {
  const { title, open, setOpen, data, onSelectRow, allowSelect = true } = props;

  function buttonFormatter(cell) {
    return (
      <>
        <Tooltip title="Select">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              onSelectRow(cell);
              setOpen(false);
            }}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  const columns = [
    { dataField: "fullName", text: "Full Name", sort: true },
    {
      dataField: "town",
      text: "Town",
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "province",
      text: "Province",
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    allowSelect && {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
    },
  ];

  useEffect(() => {
    // console.log("Data:", data);
  }, [data]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="table-dialog"
    >
      <DialogTitle id="table-dialog">{title}</DialogTitle>
      <DialogContent>
        {!data || !data.length ? (
          <p>{"No available suppliers"}</p>
        ) : (
          <Table
            data={getData(data)}
            columns={columns}
            pagination={paginationFactory()}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupplierTableDialog;
