import { Dialog, makeStyles } from "@material-ui/core";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SERVER_URL } from "../../../api";
import "../../../customStyles/image_carousel.css";

const useStyles = makeStyles(() => ({
  media: {
    display: "block",
    width: "100%",
    maxHeight: "80vh",
    objectFit: "contain",
    objectPosition: "center",
    margin: "auto",
  },
  dialogContent: {
    padding: 30,
    overflow: "hidden",
  },
}));

const ImageCarouselDialog = ({ open, setOpen, images }) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: images?.length > 1,
    infinite: images?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const isVideo = (url) => {
    return url
      ? typeof url === "object"
        ? url?.name?.endsWith(".mp4") ||
          url?.name?.endsWith(".webm") ||
          url?.name?.endsWith(".ogg")
        : url?.endsWith(".mp4") ||
          url?.endsWith(".webm") ||
          url?.endsWith(".ogg")
      : false;
  };

  const getMediaSrc = (media) => {
    console.log("MEDIA", media);
    return typeof media === "string"
      ? `${SERVER_URL}/${media}`
      : URL.createObjectURL(media);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogContent }}
    >
      <div className="image-slider-container">
        <Slider {...settings}>
          {images?.map((media, index) => (
            <div key={index}>
              {isVideo(media) ? (
                <video
                  controls
                  className={classes.media}
                  src={getMediaSrc(media)}
                />
              ) : (
                <img
                  src={getMediaSrc(media)}
                  alt={`Artwork Media ${index}`}
                  className={classes.media}
                />
              )}
            </div>
          ))}
        </Slider>
      </div>
    </Dialog>
  );
};

export default ImageCarouselDialog;
