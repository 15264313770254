import { Autorenew } from "@material-ui/icons";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory, useLocation } from "react-router-dom";
import "../../../customStyles/bootstrap_table.css";

const { SearchBar, ClearSearchButton } = Search;

export const buttonsStyle = {
  maxWidth: "30px",
  maxHeight: "30px",
  minWidth: "30px",
  minHeight: "30px",
  marginLeft: "1em",
};

export function booleanFormatter(cell) {
  return cell ? (
    <CheckBox style={{ color: "#D91E48" }} />
  ) : (
    <CheckBoxOutlineBlank style={{ color: "#D91E48" }} />
  );
}

export function longTextFormatter(cell) {
  return (
    <td className="truncate">
      <div dangerouslySetInnerHTML={{ __html: cell }}></div>
    </td>
  );
}

function extractContent(s) {
  if (!s) return "----";
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function substringFormatter(cell) {
  const text = extractContent(cell);
  return text.length > 50 ? text.substring(0, 50) + "..." : text;
}

const buildDate = (date) => {
  if (Object.prototype.toString.call(date) !== "[object Date]") return;
  const displayDate = `${date.getDate()}/${date.getMonth() +
    1}/${date.getFullYear()}  ${date.getHours()}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;
  return displayDate;
};

export function roleFormatter(role) {
  if (role) return <span className="badge badge-primary">{role}</span>;
}

export function dateFormatter(cell) {
  let date = new Date(cell);
  date = buildDate(date);

  return date;
}

export function getCurrentTablePage(location) {
  const searchParams = new URLSearchParams(location?.search);
  return parseInt(searchParams?.get("page"));
}

const customTotal = (from, to, size) => (
  <span
    className="react-bootstrap-table-pagination-total"
    style={{ paddingLeft: "5px" }}
  >
    Showing {from}-{to} of {size} results
  </span>
);

const rowClasses = (row) =>
  row?.active === false || row?.deleted ? "inactive-row" : "";

export default function Table({
  data,
  columns,
  filters,
  resetFilters,
  ...tableProps
}) {
  const history = useHistory();
  const location = useLocation();

  const pagination = paginationFactory({
    page: getCurrentTablePage(location) || 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    alwaysShowAllBtns: false,
    onPageChange: function(page, sizePerPage) {
      history.push(`?page=${page}`);
    },
    onSizePerPageChange: function(page, sizePerPage) {},
  });

  useEffect(() => {
    console.log("NEW DATA", data);
    console.log("COLUMNS", columns);
  }, [data]);

  return (
    <ToolkitProvider
      bootstrap4
      keyField="id"
      data={data}
      columns={columns}
      search
    >
      {(props) => (
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <SearchBar placeholder="Search" {...props.searchProps} />
              <ClearSearchButton className="ml-3" {...props.searchProps} />
            </div>
            {filters && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={resetFilters}
                title="Clear filters"
              >
                <span>
                  <Autorenew />
                </span>
              </button>
            )}
          </div>
          {filters}
          <br />
          <br />
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bordered={false}
            // remote
            pagination={pagination}
            striped
            rowClasses={rowClasses}
            {...tableProps}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
}
